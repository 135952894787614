@import "_variables";
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{
  font-family: 'Bitter', serif;
  &.home{
    .base-footer, .sub-footer{
      display: none;
    }
  }
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.title-image-back{
  height: auto;
  .tag{
    position: relative;
    left:0;
    padding: 75px 0;
    h1{
      font-size: 3rem;
    }
  }
}

p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}
/*.home{
  nav.navbar.bootsnav{
    background-color: transparent!important;
    border:none!important;
  }
}*/

.navbar-brand{
  height: auto;
  img{
    max-height: 65px;
  }
}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu{
  border:1px #fff solid;
  background-color: $body_text_color;
  li{
    a{
      color: #FFFFFF;
      border-bottom: 0;
    }
  }
}
nav.navbar.bootsnav{
  .navbar-toggle{
    background-color: transparent;
    .fa{
      color: #FFFFFF;
    }
  }
  background-color: #373B44;
  border-bottom: none;
  padding: 20px 0;
  ul{
    li{
      a{
        color: #FFFFFF!important;
        @media(max-width: 991px){
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
}
.dropdown > .dropdown-menu{
  display: none;
}
.dropdown.on > .dropdown-menu{
  display: block;
}
.content-columns{
  background-size: cover!important;
}

.background{

  &.grow{
    &.semi-transparent{
      @include background-opacity($grow, 0.7);
    }
    background-color: $grow;
  }
  &.move{
    &.semi-transparent{
      @include background-opacity($move, 0.7);
    }
    background-color: $move;
  }
  &.invest{
    &.semi-transparent{
      @include background-opacity($invest, 0.7);
    }
    background-color: $invest;
  }
}
.text-color{
  &.grow{
  color: $grow;
  }
  &.move{
    color: $move;
  }
  &.invest{
    color: $invest;
  }
}

/* HOME PAGE */
.type-block{
  border-radius: 15px;
  @media(max-width: 767px){
    margin-bottom: 15px;
  }
  &.small{
    a{
      padding: 15px 35px;
    }
  }
  &.large{
    a{
      padding: 35px;
    }
  }
  a{
    color: #FFFFFF;
    font-size: 2.2rem;
    display: block;
    @media(max-width: 767px){
      font-size: 2rem;
    }
  }
}
.swiper-wrapper, .carousel-inner{
  .caption, .item{
    text-align: center;
    p{
      color: #FFFFFF;
    }
    .btn{
      border-radius: 5px;
      font-size: 1.3rem;
      margin-top: 2rem;
    }
  }
}
#background-carousel{
	position:fixed;
	width:100%;
	height:100%;
	z-index:-1;
  top:0;
}
.carousel,
.carousel-inner {
	width:100%;
	height:100%;
	z-index:0;
	overflow:hidden;
}
.item {
	width:100%;
	height:100%;
	background-position:center center;
	background-size:cover;
	z-index:0;
}
.border-box{
  padding: 10px 15px;
  border-width: 6px;
  border-style: solid;
  input[type="text"]{
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    font-size: 1rem!important;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 1rem;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-size: 1rem;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-size: 1rem;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-size: 1rem;
  }
  h3{
    font-size: 1.5rem;
  }
  p{
    font-size: 1rem;
    font-style: italic;
  }
  .btn{
    text-align: center;
    width: 100%;
  }
  &.move{
    h3{
      color: $move;
    }
    border-color: $move;
    input[type="text"]{
      border-color: $move;
    }
    .btn{
      background-color: $move;
      color: #FFFFFF;
      border-color: $move;
      &:hover{
        background-color: darken($move,20%);
      }
    }
  }
  &.grow{
    border-color: $grow;
    .btn{
      background-color: $grow;
      color: #FFFFFF;
      border-color: $grow;
      &:hover{
        background-color: darken($grow,20%);
      }
    }
    h3{
      color: $grow;
    }
    input[type="text"]{
      border-color: $grow;
    }
  }
  &.invest{
    h3{
      color: $invest;
    }
    .btn{
      background-color: $invest;
      color: #FFFFFF;
      border-color: $invest;
      &:hover{
        background-color: darken($invest,20%);
      }
    }
    border-color: $invest;
    input[type="text"]{
      border-color: $invest;
    }
  }
  .gform_wrapper {
    .gform_footer{
      padding: 0;
      margin: 15px 0 0 0;

      p, br{
        display: none;
      }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 1rem;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 1rem;
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-size: 1rem;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-size: 1rem;
    }
    .button{
      width:100%!important;
    }
    .gfield{
      padding-right: 0!important;
    }
  	form .gform_body input[type=text] {
  		width:100% !important;
        font-size: 1rem;
  	}

  	input, input[type=text], select, textarea {
  		width:100% !important;
  	}
  }
}
.main-container{
  &.grow{
    .btn:hover{
      background-color: $grow!important;
      color: #fff!important;
    }
  }
  &.move{
    .btn:hover{
      background-color: $move!important;
      color: #fff!important;
    }
  }
  &.invest{
    .btn:hover{
      background-color: $invest!important;
      color: #fff!important;
    }
  }
}
.page-sub-nav{
  ul{
    display: inline;
    list-style-type: none;
    li{
      display: inline;
      a{
        float: left;
        color: #FFFFFF;
        font-size: 1.2rem;
        padding: 15px 25px;
        &.active{
          color: $body_text_color;
        }
      }
    }
  }
}
.action-billboard{
  background-position: center center;
}
hr.background{
  height: 3px!important;
  border-bottom: none;
}
.read-next{
  list-style-type: none;
  padding-left: 0;
  margin:25px 0 50px 0;
  li{
    display: inline;
    margin-right: 25px;
    @media(max-width: 767px){
      width: 100%;
      float: left;
      margin-bottom: 5px;
    }
    a{
      &.grow{
        color: $grow;
      }
      &.move{
        color: $move;
      }
      &.invest{
        color: $invest;
      }
    }
  }
}

.base-footer{
  padding: 60px 0;
  .address{
    margin-top: 30px;
    color: #EEEDED;
    font-size: 1.1rem;
    font-weight: 300;
  }
  img{
    margin: auto;
  }
}
.sub-footer{
  background-color: #000;
  padding: 10px;
  span:first-child{
    color: #999;
  }
  span{
    color: #FFFFFF;
    font-size: 1rem;
  }
  a{
    color: #FFFFFF;
  }
}

/* FORMS */
.gform_wrapper{
  .gfield_checkbox {
    br {
      display: none!important;
    }
    li{
      display: inline!important;
    }
    input[type=checkbox]{
      margin-top: 0;
    }
  }
}
.gform_wrapper .gform_page_footer .button{
  background-color: $primary_color!important;
  color: #ffffff!important;
  border-color: $primary_color!important;
  border-radius: 30px!important;
  border-width: 1px!important;
  word-wrap: break-word !important;
  box-shadow: none!important;
  font-size: 18px!important;
  padding: 6px 12px!important;
  white-space: normal !important;
}
.gform_wrapper .gfield_required{
  float: right;
}
.form-half {
  width: 49%;
  display: inline-block;
}

.gform_wrapper .gfield_required {
  display: inline;
}

.form-area textarea {
  max-height: 7em;
}

.gform_wrapper ul li{
  &:before{
    display: none;
  }
}
/* SPECIAL GRAVITY FORMS STYLING */
.gf_full_width{
  width: 100%;
  input{
    width:99.5%!important;
  }
}
.gf_left_half, .gf_right_half{
  width: 49.5%!important;
}
.gf_left_third, .gf_middle_third, .gf_right_third{
  width: 32.5%!important;
}
.hidden-label{
  label{
    display: none!important;
  }
}
