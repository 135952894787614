$primary_color: #222;
$secondary_color: #666;
$body_text_color: #373B44;
$grow: #45ADA8;
$move: #E1B866;
$invest: #BD5532;

$global_font: 'Bitter', serif;
$header_font: 'Bitter', serif;
$brand_font_1: 'Fredericka the Great', cursive;
$brand_font_2: 'adobe-caslon-pro', serif;
